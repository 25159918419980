/* General button style (reset) */
.btn-3d {
  transition: all 0.3s;
  background: #3a52d8;

  transform-style: preserve-3d;
  &:after {
    content: "";
    position: absolute;
    z-index: -1;

    transition: all 0.3s;
    width: 100%;
    height: 40%;
    left: 0;
    top: 100%;
    background: rgba(58, 82, 216, 0.8);

    transform-origin: 0% 0%;
    transform: rotateX(-90deg);
  }
  &:hover {
    background: #3a52d8;

    transform: rotateX(15deg);
  }
}

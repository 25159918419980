@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/mixins/";
@import "../node_modules/bootstrap/scss/navbar";
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;800&display=swap');

body{
  font-family: 'Cairo', sans-serif;
}
$font-sizes: (
  1: 12px,
  2: 14px,
  3: 16px,
  4: 18px,
  5: 20px,
  6: 22px,
  7: 24px,
  8: 26px,
  9: 30px,
  10: 38px,
  11: 42px,
  12: 50px,
);

$utilities: map-merge(
  $utilities,
  (
    "font-size":
      map-merge(
        map-get($utilities, "font-size"),
        (
          values: $font-sizes,
        )
      ),
  )
);
$danger: #ec3449;
$danger-rgb: 236, 52, 73;
// $theme-colors: (
//   "danger": $danger,
// );
@import "~bootstrap/scss/bootstrap";
:root {
  --bs-danger: #{$danger};
  --bs-red: #{$danger};
  --bs-danger-rgb: #{$danger-rgb};
    --bs-body-font-family: 'Cairo', sans-serif;

}

.fp-watermark {
  display: none;
}



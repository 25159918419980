header {
  min-height: 26.2em;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../assets/images/tinified/hbg.jpg");
  z-index: 1;
  &:after {
    z-index: -1;
    background-color: rgba(32, 35, 44, 0.8);
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}


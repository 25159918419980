// .section {
//   height: 100vh;
// }
.section-1 {
  background-color: rgba(0, 0, 0, 0);
  background-repeat: no-repeat;
  // background-image: url("../../assets/images/2-layers.jpg");
  background-size: cover;
  background-position: center top;
  .carousel-item{
    background-size: cover;
    background-position: center top;
    min-height: 100vh;
  }
  @media (max-width: 768px) {
    .carousel-item:nth-child(0) {
      background-image: url("../../assets/images/mob/1.webp")!important;
    }
    .carousel-item:nth-child(1) {
      background-image: url("../../assets/images/mob/1.webp")!important;
    }
    .carousel-item:nth-child(2) {
      background-image: url("../../assets/images/mob/2.webp")!important;
    }
    .carousel-item:nth-child(3) {
      background-image: url("../../assets/images/mob/3.webp")!important;
    }
  }


  .scroll_down {
    cursor: pointer;
    text-transform: uppercase;
    width: 2rem;
    height: 4rem;
    bottom: 50px;
    border-radius: 5px;
    left: calc(50% - 16px);
    &:hover {
      color: #17191f;
      -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 2
        both;
      animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 2 both;
    }
  }
  .text-content {
    // background-image: url("../../assets/images/gym.png");
    background-size: contain;
    background-repeat: no-repeat;
  }
}
@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}

.section-2 {
  // background-color: rgba(0, 0, 0, 0);
  // background-repeat: no-repeat;
  // background-image: url("../../assets/images/bg_img.webp");
  // background-size: contain;
  // background-position: left bottom;
  .text-content {
    background-image: url("../../assets/images/section_start2.jpg");
    background-repeat: no-repeat;
    background-size: 70%;
    background-position: top left;
  }
}
.testmonials {
  background: rgb(58, 82, 216);
  background: linear-gradient(
    135deg,
    rgba(58, 82, 216, 1) 0%,
    rgba(32, 62, 138, 1) 100%
  );
  .swiper-slide {
    transition: all 0.3s ease;

    &.swiper-slide {
      opacity: 0;
    }
    &.swiper-slide-next,
    &.swiper-slide-prev {
      transform: scale(0.9);
      opacity: 0.2;
      .text {
        background-color: transparent!important;
        color: white!important;
      }
      .down-icon {
       display: none!important;
      }
    }
    &.swiper-slide-active {
      z-index: 2;
      opacity: 1;
    }
    .text {
      svg {
        color: #3a52d8;
        font-size: 100px;
        opacity: 0.13;
        margin-bottom: -30px;
      }
    }
    .down-icon {
      margin-top: -13px;
    }
  }
}

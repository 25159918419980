.quiz-container {
  box-shadow: 0 6px 40px rgb(0 125 255 / 45%);
  .hidden-radio {
    &.checked label,input:checked +label {
      background: #31d48c;
      color: #fff;
    }
    .radio-btn {
      color: #31d48c;
      margin-right: 5px;
      border: 1px solid #31d48c;
      box-shadow: 3px 3px 12px 0 rgb(49 212 140 / 48%);
      background: #fff;
      border-radius: 50px;
      &:hover {
        background: #31d48c;
        color: #fff;
      }
    }
  }
}
